define("ember-notifier/utils/default-value", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = defaultValue;
  /**
   * Fallback to a default value if the property is undefined.
   *
   * Source:
   * [github.com/cibernox/ember-power-select](https://github.com/cibernox/ember-power-select/blob/master/addon/utils/computed-fallback-if-undefined.js)
   *
   * @function defaultValue
   * @param {string} fallback Fallback value if property is undefined.
   * @return {string} The property value.
   */
  function defaultValue(fallback) {
    return (0, _object.computed)({
      get() {
        return fallback;
      },
      set(_, value) {
        return value === undefined ? fallback : value;
      }
    });
  }
});