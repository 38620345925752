define("ember-notifier/components/ember-notifier-notification/close/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "m75UgtQK",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"button\"],[14,0,\"ember-notifier-close-button\"],[14,\"aria-label\",\"Dismiss alert\"],[14,\"title\",\"Dismiss this notification\"],[15,\"onclick\",[28,[37,3],[[30,0],[33,4]],null]],[14,4,\"button\"],[12],[1,\"\\n    ×\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"action\",\"close\"]]",
    "moduleName": "ember-notifier/components/ember-notifier-notification/close/template.hbs",
    "isStrictMode": false
  });
});