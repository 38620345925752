define("liquid-wormhole/components/liquid-wormhole", ["exports", "@ember/component", "@ember/utils", "@ember/service", "@ember/object", "@ember/object/computed", "@ember/object/internals", "liquid-wormhole/templates/components/liquid-wormhole"], function (_exports, _component, _utils, _service, _object, _computed, _internals, _liquidWormhole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _liquidWormhole.default,
    to: (0, _computed.reads)('destination'),
    liquidWormholeService: (0, _service.inject)('liquid-wormhole'),
    stack: (0, _object.computed)(function () {
      return (0, _internals.guidFor)(this);
    }),
    // Truthy value by default
    value: true,
    init() {
      const wormholeClass = this.get('class');
      const wormholeId = this.get('stack') || this.get('id');
      this.set('wormholeClass', wormholeClass);
      this.set('wormholeId', wormholeId);
      if ((0, _utils.typeOf)(this.get('send')) !== 'function') {
        this.set('hasSend', true);
      }
      this._super(...arguments);
    },
    didUpdateAttrs() {
      this._super(...arguments);
      this.get('liquidWormholeService').removeWormhole(this, this.get('to'));
      this.get('liquidWormholeService').appendWormhole(this, this.get('to'));
    },
    didInsertElement() {
      const nodes = this.$().children();
      this.set('nodes', nodes);
      this.element.className = 'liquid-wormhole-container';
      this.element.id = '';
      this.get('liquidWormholeService').appendWormhole(this, this.get('to'));
      this._super.apply(this, arguments);
    },
    willDestroyElement() {
      this.get('liquidWormholeService').removeWormhole(this, this.get('to'));
      this._super.apply(this, arguments);
    }
  });
});