define("ember-table/components/ember-thead/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "1XAeNDjA",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"wrappedRows\"]]],null]],null],null,[[[41,[48,[30,2]],[[[1,\"    \"],[18,2,[[28,[37,5],null,[[\"cells\",\"isHeader\",\"rowsCount\",\"row\"],[[30,1,[\"cells\"]],[30,1,[\"isHeader\"]],[30,1,[\"rowsCount\"]],[50,\"ember-tr\",0,null,[[\"api\"],[[30,1]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,7],null,[[\"api\"],[[30,1]]]]],[1,\"\\n\"]],[]]]],[1]],null]],[\"api\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"ember-tr\"]]",
    "moduleName": "ember-table/components/ember-thead/template.hbs",
    "isStrictMode": false
  });
});