define("ember-could-get-used-to-this/-private/modifiers", ["exports", "@ember/modifier", "@ember/destroyable", "@ember/application"], function (_exports, _modifier, _destroyable, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Modifier = void 0;
  _exports.modifier = modifier;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class FunctionalModifierManager {
    constructor() {
      _defineProperty(this, "capabilities", (0, _modifier.capabilities)('3.22'));
    }
    createModifier(fn, args) {
      return {
        fn,
        args,
        element: undefined,
        destructor: undefined
      };
    }
    installModifier(state, element) {
      state.element = element;
      this.setupModifier(state);
    }
    updateModifier(state) {
      this.destroyModifier(state);
      this.setupModifier(state);
    }
    setupModifier(state) {
      let {
        fn,
        args,
        element
      } = state;
      state.destructor = fn(element, args.positional, args.named);
    }
    destroyModifier(state) {
      if (typeof state.destructor === 'function') {
        state.destructor();
      }
    }
    getDebugName(fn) {
      return fn.name || '(anonymous function)';
    }
  }
  const FUNCTIONAL_MODIFIER_MANAGER = new FunctionalModifierManager();
  const FUNCTIONAL_MODIFIER_MANAGER_FACTORY = () => FUNCTIONAL_MODIFIER_MANAGER;
  function modifier(fn) {
    return (0, _modifier.setModifierManager)(FUNCTIONAL_MODIFIER_MANAGER_FACTORY, fn);
  }

  ////////////

  class Modifier {
    constructor(owner, args) {
      (0, _application.setOwner)(this, owner);
      this.args = args;
    }
    setup() {}
  }
  _exports.Modifier = Modifier;
  class ClassModifierManager {
    constructor(owner) {
      _defineProperty(this, "capabilities", (0, _modifier.capabilities)('3.22'));
      this.owner = owner;
    }
    createModifier(Class, args) {
      let instance = new Class(this.owner, args);
      return {
        Class,
        instance,
        args,
        element: undefined
      };
    }
    installModifier(state, element) {
      state.element = element;
      this.setupModifier(state);
    }
    updateModifier(state) {
      if (typeof state.instance.update === 'function') {
        state.instance.update();
      } else {
        this.destroyModifier(state);
        let {
          Class,
          args
        } = state;
        state.instance = new Class(this.owner, args);
        this.setupModifier(state);
      }
    }
    setupModifier({
      instance,
      element
    }) {
      instance.element = element;
      instance.setup();
      if (typeof instance.teardown === 'function') {
        (0, _destroyable.registerDestructor)(instance, () => instance.teardown());
      }
    }
    destroyModifier(state) {
      (0, _destroyable.destroy)(state.instance);
    }
    getDebugName(Class) {
      return Class.name || '(anonymous class)';
    }
  }
  (0, _modifier.setModifierManager)(owner => new ClassModifierManager(owner), Modifier);
});