define("liquid-wormhole/components/liquid-append", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    didUpdateAttrs() {
      if (this.get('replaceNodes')) {
        const nodes = this.get('nodes');
        this.$().children().remove();
        this.$().append(nodes);
      }
    },
    didInsertElement() {
      const notify = this.get('notify');
      const nodes = this.get('nodes');
      if (notify && notify.willAppendNodes) {
        notify.willAppendNodes(this.element);
      }
      this.$().append(nodes);
      if (notify && notify.didAppendNodes) {
        notify.didAppendNodes(this.element);
      }
    }
  });
});