define("liquid-wormhole/templates/components/liquid-destination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "oT133w3T",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"liquid-destination-stack\"],[12],[1,\"\\n\"],[6,[39,3],null,[[\"value\",\"notify\",\"renderWhenFalse\",\"name\",\"matchContext\",\"stackName\"],[[30,1,[\"lastObject\"]],[30,0],true,\"liquid-wormhole\",[33,4],[30,1,[\"name\"]]]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,5],null,[[\"nodes\",\"replaceNodes\",\"notify\",\"click\"],[[30,2,[\"nodes\"]],[30,2,[\"_replaceNodes\"]],[30,2,[\"wormhole\"]],[30,2,[\"wormhole\",\"click\"]]]]]],[1,\"\\n\"]],[2]]]]],[1,\"  \"],[13],[1,\"\\n\"]],[1]],null]],[\"stack\",\"item\"],false,[\"each\",\"-track-array\",\"stacks\",\"liquid-versions\",\"matchContext\",\"liquid-append\"]]",
    "moduleName": "liquid-wormhole/templates/components/liquid-destination.hbs",
    "isStrictMode": false
  });
});