define("ember-notifier/components/ember-notifier-notification/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3SUdruWh",
    "block": "[[[41,[33,1],[[[1,\"  \"],[46,[33,1],null,[[\"title\",\"message\",\"setOption\"],[[33,3],[33,4],[33,5]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,2],[14,0,\"ember-notifier-title\"],[12],[1,\"\\n    \"],[1,[30,0,[\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[14,0,\"ember-notifier-message\"],[12],[1,\"\\n    \"],[1,[30,0,[\"message\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"contentComponent\",\"component\",\"title\",\"message\",\"setOption\"]]",
    "moduleName": "ember-notifier/components/ember-notifier-notification/content/template.hbs",
    "isStrictMode": false
  });
});