define("ember-notifier/components/ember-notifier-notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "RSyLpHhl",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[46,[33,4],null,[[\"icon\"],[[33,5,[\"icon\"]]]],null],[1,\"\\n  \"],[46,[33,6],null,[[\"title\",\"message\",\"contentComponent\",\"setOption\"],[[33,5,[\"title\"]],[33,5,[\"message\"]],[33,5,[\"contentComponent\"]],[28,[37,7],[[30,0],\"setOption\"],null]]],null],[1,\"\\n  \"],[46,[33,8],null,[[\"close\"],[[33,9]]],null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"component\",\"iconComponent\",\"notification\",\"contentComponent\",\"action\",\"closeComponent\",\"close\"]]",
    "moduleName": "ember-notifier/components/ember-notifier-notification/template.hbs",
    "isStrictMode": false
  });
});