define("ember-sortable/components/sortable-item", ["exports", "ember", "ember-sortable/templates/components/sortable-item", "ember-sortable/mixins/sortable-item"], function (_exports, _ember, _sortableItem, _sortableItem2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ember.default.Component.extend(_sortableItem2.default, {
    layout: _sortableItem.default
  });
});