define("ember-sortable/utils/will-transition", ["exports", "ember-sortable/utils/transition-duration"], function (_exports, _transitionDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = willTransition;
  /**
    @private
    @method willTransition
    @param {HTMLElement} el
    @return {Boolean}
  */
  function willTransition(el) {
    return (0, _transitionDuration.default)(el) > 0;
  }
});