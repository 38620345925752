define("ember-notifier/mixins/swipe-support", ["exports", "@ember/object/computed", "@ember/object/mixin", "@ember/utils"], function (_exports, _computed, _mixin, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let meta;

  // Source: https://github.com/offirgolan/ember-burger-menu/blob/master/addon/mixins/swipe-support.js
  var _default = _exports.default = _mixin.default.create({
    minSwipeDistance: (0, _computed.readOnly)('notification.minSwipeDistance'),
    maxSwipeTime: (0, _computed.readOnly)('notification.maxSwipeTime'),
    onSwipe(/* direction, target */
    ) {},
    touchStart(event) {
      this._super(...arguments);
      const touch = event.touches[0];
      meta = {
        target: event.target,
        start: {
          x: touch.pageX,
          y: touch.pageY,
          time: new Date().getTime()
        }
      };
    },
    touchMove(event) {
      this._super(...arguments);
      const touch = event.touches[0];
      meta.differences = {
        x: touch.pageX - meta.start.x,
        y: touch.pageY - meta.start.y
      };

      // Compute swipe direction
      if ((0, _utils.isNone)(meta.isHorizontal)) {
        meta.isHorizontal = Math.abs(meta.differences.x) > Math.abs(meta.differences.y);
      }

      // A valid swipe event uses only one finger
      if (event.touches.length > 1) {
        meta.isInvalid = true;
      }
    },
    touchEnd() {
      this._super(...arguments);
      const minSwipeDistance = this.get('minSwipeDistance');
      const maxSwipeTime = this.get('maxSwipeTime');
      const elapsedTime = new Date().getTime() - meta.start.time;
      if (meta.isHorizontal && !meta.isInvalid && Math.abs(meta.differences.x) >= minSwipeDistance && elapsedTime <= maxSwipeTime) {
        this.onSwipe(meta.differences.x > 0 ? 'right' : 'left', meta.target);
      }
    }
  });
});