define("ember-notifier/components/ember-notifier-notification/close/component", ["exports", "@ember/component", "ember-notifier/components/ember-notifier-notification/close/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * The close button component.
   *
   * Inline usage:
   * ```hbs
   * {{ember-notifier-notification/close close=close}}
   * ```
   *
   * Block param usage:
   * ```hbs
   * {{#ember-notifier-notification/close}}
   *    <button {{action close}}>Close</button>
   * {{/ember-notifier-notification/close}}
   * ```
   *
   * @class Close
   */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    classNames: ['ember-notifier-close'],
    /**
     * Closure action to dismiss the notification.
     *
     * @argument close
     * @type ember/action
     */
    close: null
  });
});