define("ember-notifier/components/ember-notifier-notification/icon/component", ["exports", "@ember/component", "ember-notifier/components/ember-notifier-notification/icon/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * The icon component.
   *
   * Inline usage:
   * ```hbs
   * {{ember-notifier-notification/icon icon=notification.icon}}
   * ```
   *
   * Block param usage:
   * ```hbs
   * {{#ember-notifier-notification/icon}}
   *    {{fa-icon notification.icon}}
   * {{/ember-notifier-notification/icon}}
   * ```
   *
   * @class Icon
   */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    classNames: ['ember-notifier-icon'],
    /**
     * Icon class name or object name.
     *
     * @argument icon
     * @type string
     */
    icon: null
  });
});