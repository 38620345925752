define("tracked-built-ins/-private/object", ["exports", "tracked-maps-and-sets/-private/util", "@ember/object"], function (_exports, _util, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COLLECTION = Symbol();
  if (false /* DEBUG */) {
    // patch mandatory setter
    // eslint-disable-next-line no-undef
    let utils = Ember.__loader.require('@ember/-internals/utils');
    let originalSetupMandatorySetter = utils.setupMandatorySetter;
    utils.setupMandatorySetter = (tag, obj, keyName) => {
      if (obj instanceof TrackedObject) {
        return;
      }
      return originalSetupMandatorySetter(tag, obj, keyName);
    };
  }
  const proxyHandler = {
    get(target, prop) {
      (0, _util.consumeKey)(target, prop);
      return target[prop];
    },
    has(target, prop) {
      (0, _util.consumeKey)(target, prop);
      return prop in target;
    },
    ownKeys(target) {
      (0, _util.consumeKey)(target, COLLECTION);
      return Reflect.ownKeys(target);
    },
    set(target, prop, value, receiver) {
      target[prop] = value;
      (0, _util.dirtyKey)(target, prop);
      (0, _util.dirtyKey)(target, COLLECTION);

      // We need to notify this way to make {{each-in}} update
      (0, _object.notifyPropertyChange)(receiver, '_SOME_PROP_');
      return true;
    },
    getPrototypeOf() {
      return TrackedObject.prototype;
    }
  };
  function createProxy(obj = {}) {
    return new Proxy(obj, proxyHandler);
  }
  class TrackedObject {
    static fromEntries(entries) {
      return createProxy(Object.fromEntries(entries));
    }
    constructor(obj = {}) {
      let proto = Object.getPrototypeOf(obj);
      let descs = Object.getOwnPropertyDescriptors(obj);
      let clone = Object.create(proto);
      for (let prop in descs) {
        Object.defineProperty(clone, prop, descs[prop]);
      }
      return createProxy(clone);
    }
  }
  _exports.default = TrackedObject;
});