define("ember-sortable/templates/components/sortable-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7NmCGVo6",
    "block": "[[[18,1,[[30,0]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-sortable/templates/components/sortable-group.hbs",
    "isStrictMode": false
  });
});