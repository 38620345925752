define("ember-sortable/utils/manager", ["exports", "ember-sortable/utils/gesture", "ember-sortable/utils/arrangement", "ember-sortable/utils/will-transition", "ember-sortable/utils/transitionend", "ember"], function (_exports, _gesture, _arrangement, _willTransition, _transitionend, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    run: {
      schedule,
      debounce
    }
  } = _ember.default;

  /**
    @class Manager
    @constructor
    @param {SortableNode} options.node
    @param {Function} options.onComplete
  */
  class Manager {
    constructor({
      node,
      onComplete,
      onCancel
    }) {
      this.node = node;
      this.root = getRoot(this.node);
      this.onComplete = onComplete || function () {};
      this.onCancel = onCancel || function () {};
      this.gesture = new _gesture.default(() => this.update());
      this.arrangement = new _arrangement.default(this.root);
    }
    start(event) {
      this.gesture.start(event);
    }
    update() {
      switch (this.gesture.state) {
        case 'dragging':
          this.drag();
          break;
        case 'dropping':
          this.drop();
          break;
        case 'clicking':
        case 'swiping':
          this.cancel();
          break;
      }
    }
    drag() {
      this.node.set('sortableState', 'dragging');
      this.arrangement.moveNode(this.node, this.gesture);
      this.schedule(() => this.renderDrag());
    }
    drop() {
      this.node.set('sortableState', 'dropping');
      this.schedule(() => this.renderDrop());
    }
    schedule(func) {
      debounce(func, 16);
    }
    renderDrag() {
      let {
        dx,
        dy
      } = this.gesture;
      let {
        element: {
          style
        }
      } = this.node;
      this.arrangement.render();
      style.transform = `translate3d(${dx}px, ${dy}px, 10px)`;
    }
    renderDrop() {
      this.arrangement.render();
      if (this.willTransition()) {
        this.node.$().one(_transitionend.default, () => this.completeDrop());
      } else {
        this.completeDrop();
      }
    }
    completeDrop() {
      let {
        parent,
        position
      } = this.arrangement.metaFor(this.node);
      this.walk(n => n.set('sortableState', null));
      this.arrangement.freeze();
      requestAnimationFrame(() => {
        this.onComplete(parent, position);
        schedule('afterRender', () => {
          this.arrangement.clear();
          this.arrangement.thaw();
        });
      });
    }
    cancel() {
      this.onCancel();
    }
    walk(func) {
      walk(this.root, func);
    }
    willTransition() {
      let {
        dx,
        dy
      } = this.arrangement.slotForNode(this.node);
      let isOffset = dx !== this.gesture.dx || dy !== this.gesture.dy;
      return isOffset && (0, _willTransition.default)(this.node.element);
    }
  }
  _exports.default = Manager;
  function getRoot(node) {
    let root = node;
    while (root.sortableParent) {
      root = root.sortableParent;
    }
    return root;
  }
  function walk(node, func) {
    func(node);
    node.sortableChildren.forEach(n => walk(n, func));
  }
});