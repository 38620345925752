define("liquid-wormhole/components/liquid-destination", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/service", "@ember/array", "perf-primitives/hash-map", "liquid-wormhole/templates/components/liquid-destination"], function (_exports, _component, _object, _computed, _runloop, _service, _array, _hashMap, _liquidDestination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _liquidDestination.default,
    classNames: ['liquid-destination'],
    classNameBindings: ['hasWormholes'],
    name: 'default',
    liquidWormholeService: (0, _service.inject)('liquidWormhole'),
    matchContext: (0, _object.computed)(() => {
      return {
        helperName: 'liquid-wormhole'
      };
    }),
    hasWormholes: (0, _computed.gt)('stacks.length', 0),
    init() {
      this._super(...arguments);
      this.stackMap = new _hashMap.default();
      this.set('stacks', (0, _array.A)());
      this.wormholeQueue = (0, _array.A)();
      const name = this.get('name');
      this.get('liquidWormholeService').registerDestination(name, this);
    },
    willDestroyElement() {
      this._super(...arguments);
      const name = this.get('name');
      this.get('liquidWormholeService').unregisterDestination(name);
    },
    appendWormhole(wormhole) {
      // The order that wormholes are rendered in may be different from the order
      // that they appear in templates, because child components get rendered before
      // their parents. This logic inserts parent components *before* their children
      // so the ordering is correct.
      var appendIndex = this.wormholeQueue.get('length') - 1;
      for (; appendIndex >= 0; appendIndex--) {
        const lastWormholeElement = this.wormholeQueue.objectAt(appendIndex).element;
        if (!wormhole.element.contains(lastWormholeElement)) {
          break; // break when we find the first wormhole that isn't a parent
        }
      }
      this.wormholeQueue.insertAt(appendIndex + 1, wormhole);
      (0, _runloop.scheduleOnce)('afterRender', this, this.flushWormholeQueue);
    },
    removeWormhole(wormhole) {
      const stackName = wormhole.get('stack');
      const stack = this.stackMap.get(stackName);
      const item = stack.find(item => item && item.wormhole === wormhole);
      const newNodes = item.get('nodes').clone();
      item.set('nodes', newNodes);
      item.set('_replaceNodes', true);
      (0, _runloop.next)(() => stack.removeObject(item));
    },
    flushWormholeQueue() {
      this.wormholeQueue.forEach(wormhole => {
        const stackName = wormhole.get('stack');
        const stack = this.stackMap.get(stackName) || this.createStack(wormhole);
        const nodes = wormhole.get('nodes');
        const value = wormhole.get('value');
        const item = _object.default.create({
          nodes,
          wormhole,
          value
        });

        // Reset visibility in case we made them visible, see below
        nodes.css({
          visibility: 'hidden'
        });
        stack.pushObject(item);
      });
      this.wormholeQueue.clear();
    },
    createStack(wormhole) {
      const stackName = wormhole.get('stack');
      const stack = (0, _array.A)([null]);
      stack.set('name', stackName);
      this.stackMap.set(stackName, stack);
      this.stacks.pushObject(stack);
      return stack;
    },
    actions: {
      willTransition() {
        // Do nothing
      },
      afterChildInsertion() {
        // Do nothing
      },
      afterTransition([{
        value,
        view
      }]) {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        // If wormholes were made w/o animations, they need to be made visible manually.
        this.$(view.element).find('.liquid-wormhole-element').css({
          visibility: 'visible'
        });

        // Clean empty stacks
        if (value === null) {
          const stacks = this.get('stacks');
          const stackName = view.get('parentView.stackName');
          const stack = this.stackMap.get(stackName);
          stacks.removeObject(stack);
          this.stackMap.delete(stackName);
        }
      }
    }
  });
});