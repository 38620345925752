define("ember-sortable/utils/identify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = identify;
  function identify(node) {
    return node.elementId;
  }
});