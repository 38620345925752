define("liquid-tether/index", ["exports", "liquid-fire/constraint"], function (_exports, _constraint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.onCloseTether = onCloseTether;
  _exports.onOpenTether = onOpenTether;
  _exports.target = target;
  function target(name) {
    return new _constraint.default('parentElementClass', `${name}`);
  }
  function onOpenTether() {
    return new _constraint.default('newValue', value => value !== null);
  }
  function onCloseTether() {
    return new _constraint.default('newValue', value => value === null);
  }
});