define("ember-sortable/components/sortable-group", ["exports", "ember", "ember-sortable/templates/components/sortable-group", "@ember/object"], function (_exports, _ember, _sortableGroup, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    A,
    Component,
    get,
    set,
    run
  } = _ember.default;
  const a = A;
  const NO_MODEL = {};
  var _default = _exports.default = Component.extend({
    layout: _sortableGroup.default,
    /**
      @property direction
      @type string
      @default y
    */
    direction: 'y',
    /**
      @property model
      @type Any
      @default null
    */
    model: NO_MODEL,
    /**
      @property items
      @type Ember.NativeArray
    */
    items: (0, _object.computed)(() => a()),
    /**
      Position for the first item.
      If spacing is present, first item's position will have to change as well.
      @property itemPosition
      @type Number
    */
    itemPosition: (0, _object.computed)(function () {
      let direction = this.get('direction');
      return this.get(`sortedItems.firstObject.${direction}`) - this.get('sortedItems.firstObject.spacing');
    }).volatile(),
    /**
      @property sortedItems
      @type Array
    */
    sortedItems: (0, _object.computed)(function () {
      let items = a(this.get('items'));
      let direction = this.get('direction');
      return items.sortBy(direction);
    }).volatile(),
    /**
      Register an item with this group.
      @method registerItem
      @param {SortableItem} [item]
    */
    registerItem(item) {
      this.get('items').addObject(item);
    },
    /**
      De-register an item with this group.
      @method deregisterItem
      @param {SortableItem} [item]
    */
    deregisterItem(item) {
      this.get('items').removeObject(item);
    },
    /**
      Prepare for sorting.
      Main purpose is to stash the current itemPosition so
      we don’t incur expensive re-layouts.
      @method prepare
    */
    prepare() {
      this._itemPosition = this.get('itemPosition');
    },
    /**
      Update item positions (relatively to the first element position).
      @method update
    */
    update() {
      let sortedItems = this.get('sortedItems');
      // Position of the first element
      let position = this._itemPosition;

      // Just in case we haven’t called prepare first.
      if (position === undefined) {
        position = this.get('itemPosition');
      }
      sortedItems.forEach(item => {
        let dimension;
        let direction = this.get('direction');
        if (!get(item, 'isDragging')) {
          set(item, direction, position);
        }

        // add additional spacing around active element
        if (get(item, 'isBusy')) {
          position += get(item, 'spacing') * 2;
        }
        if (direction === 'x') {
          dimension = 'width';
        }
        if (direction === 'y') {
          dimension = 'height';
        }
        position += get(item, dimension);
      });
    },
    /**
      @method commit
    */
    commit() {
      let items = this.get('sortedItems');
      let groupModel = this.get('model');
      let itemModels = items.mapBy('model');
      let draggedItem = items.findBy('wasDropped', true);
      let draggedModel;
      if (draggedItem) {
        set(draggedItem, 'wasDropped', false); // Reset
        draggedModel = get(draggedItem, 'model');
      }
      delete this._itemPosition;
      run.schedule('render', () => {
        items.invoke('freeze');
      });
      run.schedule('afterRender', () => {
        items.invoke('reset');
      });
      run.next(() => {
        run.schedule('render', () => {
          items.invoke('thaw');
        });
      });
      if (groupModel !== NO_MODEL) {
        this.sendAction('onChange', groupModel, itemModels, draggedModel);
      } else {
        this.sendAction('onChange', itemModels, draggedModel);
      }
    }
  });
});